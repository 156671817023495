// theme.js
export default {
    colors: {
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      gray: {
        50: '#f7fafc',
        900: '#171923',
      },

      purple: '#B794F4' 
      

    },
  }